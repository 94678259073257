// 🏓 SASS Variables
$green: #35af45;
$orange: #ff8000;
$blue: #00b1ff;
$mauve: #b400ff;
$red: #ff4800;

// 🧱 CSS Variables
:root {
  --green: #{$green};
  --orange: #{$orange};
  --blue: #{$blue};
  --mauve: #{mauve};
}
