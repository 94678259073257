@import 'colors.scss';

.PaymentConfirmation {
  color: white;
  background: linear-gradient(to top right, darken($blue, 15), $blue);
  height: 100%;

  &.failure {
    background: linear-gradient(to top right, darken($mauve, 15), $mauve);
  }

  &.refused {
    background: linear-gradient(to top right, darken($red, 15), $red);
  }

  &.paid {
    background: linear-gradient(to top right, darken($green, 15), $green);
  }

  > .content {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    > p {
      max-width: 600px;
      text-align: center;
    }
  }
}
